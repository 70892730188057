.faq_wrapper {
  padding: 0rem 2rem;
  color: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.faq_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
  padding: 3rem 0;
}

.faq_subcontainer {
  width: 65%;
  align-items: start;
  justify-content: start;
}

.faq_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0 1rem 1rem;
}

.question_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding-bottom: 1rem;
  border-bottom: 1.5px solid #000;
  cursor: pointer;
}

.question_side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
}

.faq_question {
  font-size: 22px;
  font-weight: 500;
}

.faq_answer {
  font-size: 16px;
  font-weight: 450;
}

.dropdown {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 1rem;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

@media screen and (max-width: 576px) {
  .faq_wrapper {
    padding: 0rem 1rem 0 2rem;
    color: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .faq_heading {
    padding: 2rem 0 1rem 0;
    font-size: 36px;
  }

  .faq_subcontainer {
    width: 100%;
  }

  .faq_content {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .faq_question {
    font-size: 18px;
    font-weight: 500;
  }

  .faq_answer {
    font-size: 15px;
    font-weight: 450;
  }
}
