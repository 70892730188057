.brandscontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3rem 0 4rem 0;
    flex-direction: column;
    gap: 1rem;
  }
  
  .brandssubcontainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 65%;
    color: #191919;
    gap: 1.5rem;
    padding: 1rem;
  }
  
  .brandsheading {
    font-size: 48px;
    font-weight: 400;
    color: #191919;
    line-height: 1.25;
  }
  
  .brands__tags_container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2rem;
    width: auto;
    height: 120px;
    padding: 0 1rem;
  }
  
  .brands__tags {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 10px;
    padding: 5px;
    min-width: 160px;
    height: 100px;
  }
  
  .brands__tags > img {
    object-fit: cover;
    width: 150px;
  }
  
  @media screen and (max-width: 567px) {
    .brandscontainer {
      padding: 3rem 1rem 2rem 2rem;
    }
  
    .brandssubcontainer {
      width: 100%;
      color: #191919;
      padding: 0rem;
    }
  
    .brandsheading {
      font-size: 36px;
    }
  
    .brands__tags {
      min-width: 120px;
      height: 80px;
    }
  
    .brands__tags > img {
      width: 100px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .brandsheading {
      font-size: 40px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .brandsheading {
      font-size: 32px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .brandsheading {
      font-size: 28px;
    }
  }
  
  @media screen and (max-width: 375px) {
    .brandsheading {
      font-size: 24px;
    }
  }
  