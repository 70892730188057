.events_container {
    display: flex;
    padding: 1rem 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #fff;
  }
  
  .events_subcontainer {
    display: flex;
    align-items: start;
    justify-content: start;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
  
  .events_heading {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.25;
  }
  
  .events_subheading {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.25;
  }
  
  .events_info {
    font-size: 18px;
    font-weight: 450;
  }
  
  .events_card_container {
    display: flex;
    padding: 2rem 0 0 0;
    align-items: flex-start;
    justify-content: start;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .events_card {
    border: 1.5px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem;
    width: calc(33.33% - 0.75rem);
    height: 330px;
    border-radius: 6px;
    gap: 1rem;
    transition: ease-in-out 0.45s; 
      background-color: #fff;
  }
  
  .events_card:hover {
    height: auto;
    transform: translateY(-10px); /* Move the card up slightly */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.742); /* Add shadow for 3D effect */
  }
  
  .events_card_heading {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: height 0.3s ease;
  }
  
  .full_heading {
    height: auto; /* Allows the heading to expand */
    -webkit-line-clamp: unset; /* Removes the line clamp */
    overflow: visible; /* Shows full text */
  }
  
  @media screen and (max-width: 921px) {
    .events_card_container {
      display: flex;
      padding: 1rem 0 0 0;
      align-items: flex-start;
      justify-content: start;
      width: 100%;
      flex-wrap: wrap;
      gap: 1rem;
    }
  
    .events_card {
      border: 1.5px solid #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 1rem;
      width: 100%;
      height: 380px;
      border-radius: 6px;
      gap: 1rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .events_container {
      padding: 3rem 1rem 2rem 2rem;
    }
  
    .events_subcontainer {
      width: 100%;
    }
  
    .events_heading {
      font-size: 36px;
    }
  
    .events_card_container {
      display: flex;
      padding: 1rem 0 0 0;
      align-items: flex-start;
      justify-content: start;
      width: 100%;
      flex-wrap: wrap;
      gap: 1rem;
    }
  
    .events_card {
      border: 1.5px solid #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 1rem;
      width: 100%;
      height: 380px;
      border-radius: 6px;
      gap: 1rem;
    }
  }
  