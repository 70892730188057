ol.array,
ul.array {
  margin: 0;
  padding: 0;
  list-style: none;
  /* display: grid; */
  /* grid-gap: 1rem; */
}

/*  li {
  display: grid;
  grid-template-columns: 0 1fr;
  grid-gap: 1.75em;
  align-items: start;
  font-size: 1.5rem;
  line-height: 1.25;
  max-width: 25ch;
} */

ul.array li::before {
  content: "➜";
  font-size: 1em;
  margin-right: 5px;
}

ol.array {
  --li-bg: purple;
  counter-reset: orderedlist;
}
ol.array li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  font-family: "Indie Flower";
  font-size: 1.25em;
  line-height: 0.75;
  width: 1.5rem;
  padding-top: 0.25rem;
  text-align: center;
  color: #fff;
  background-color: var(--li-bg);
  border-radius: 0.25em;
}
