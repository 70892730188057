.carousel_resp {
  position: relative;
  width: 100vw;
}

.carousel_root {
  position: relative;
  width: 100vw;
}

.control-arrow {
  overflow: hidden;
}

.Login-Loader-bg, .Login-loader-img {
  background-color: #ffffff;
  height: 100%;
  width: 99%;
}

.Login-loader-img img {
  position: relative;
  animation-name: Login-loader;
  animation-duration: 5s;
  animation-direction: normal;
  animation-iteration-count: infinite;
}

.Login-loader-img {
  padding-top: 25px;
}

.Login-loader-shadow {
  border-radius: 50%;
  width: 180px;
  height: 30px;
  margin-top: 50px;
  background-color: grey;
  position: relative;
  animation-name: shadow;
  animation-duration: 5s;
  animation-direction: normal;
  animation-iteration-count: infinite;
}

@keyframes Login-loader {
  0%   {top:0px;}
  50%  {top:50px;}
  100% {top:0px;}
}

@keyframes shadow {
  0%   {background-color: lightgray;}
  50%  {background-color: gray;}
  100% {background-color: lightgray;}
}

.land {
  justify-content: space-around;
  margin: 20px auto 20px auto;
}

.land-first p {
  font-size: 54px;
  color: #111515;
  font-weight: 900;
  font-family: Montserrat;
}

.land-first2 p {
  font-size: 54px;
  font-weight: 900;
  font-family: Montserrat;
  margin: 0px !important;
  padding: 10px !important;
}

.programs-heading {
  font-weight: 800;
  font-size: 1.5rem;
  color: #000000;
}

.programs-subheading {
  color: #5a6273;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 1.4;
}

.programs-body {
  padding: 2rem;
  -webkit-box-align: center;
  border-radius: 8px;
  min-height: 450px;
  max-height: 450px;
}

.programs-img {
  height: 10rem;
  max-width: 100%;
  padding: 2rem;
}

.cards-cont {
  justify-content: space-around;
  background-color: #f8faff;
  padding-top: 30px;
  padding-bottom: 30px;
}

.text-div {
  margin: 10px 0 10px;
}

.land img {
  margin: 5px auto 5px auto;
  height: auto;
}

.land2 {
  background-color: #fff;
}

.land2 img {
  width: 100%;
  height: auto;
  margin: 10px auto 10px auto;
}

.land2 .pp p{
  font-size: 24px;
  color: gray;
}

.elw {
  color: #FFFFFF;
  font-family: "Montserrat", Sans-serif;
  font-size: 50px;
  font-weight: 600;
}

#talk {
  background-color: #f99c38 !important;
  color: #fff !important;
  margin-bottom: 20px;
}

.elw2 {
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 50px;
  font-weight: 600;
}

#talk2 {
  background-color: #b32800 !important;
  color: #fff !important;
  margin-bottom: 20px;
}

#butt {
  margin: auto;
}

#butt img {
  width: 100%;
  height: auto;
  padding: 20px;
}


.DeskFooter .footer-top{
  border-bottom: 1px solid silver;
}

.DeskFooter .footer-bottom{
   padding: 20px;
   /* margin:10px; */
   text-align: center;
}

.footer-top {
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
  background: white;
  padding-top: 50px;
  font-family: Montserrat !important;
  justify-content: center;
  padding-bottom: 20px;
}

.footer-bottom {
  background: white;
  color: #000;
  font-weight: 300;
  padding-top: 10px;
  font-size: 13px;
  font-family: Montserrat !important;
  justify-content: center;
  padding-bottom: 10px;
}

.footer-logo {
  margin-right: 15px;
  float: left;
}

.footer-logo-text {
  font-size: 16px;
  font-weight: 300;
  margin-top: -18px !important;
}

.footer-item {
  padding: 0px 20px 30px 18px;
}

.footer-item-other {
  padding: 0px 20px 30px 18px;
  margin-top: 0px !important;
}

.list-item, .footer-links {
  font-weight: 300;
  font-size: 16px !important;
  text-decoration: none !important;
  color: #000 !important;
  padding: 0 !important;
  padding-bottom: 2px !important;
}

.footer-headings {
  margin: 0px !important;
  font-size: 16px !important;
  margin-bottom: 15px !important;
}

.footer-social-icon {
  color: #b32800;
}

.footer-links:hover {
  color: #b32800 !important;
}

.social-list {
  display: inline-flex;
  flex-direction: row;
}

.social-list-item {
  padding: 0px !important;
  margin-right: 10px;
}

.footer-social-links {
  width: 27px;
  height: 27px;
  text-align: center;
}

.footer-social-links:hover {
  color: #f99c38 !important;
}

.whatsapp {
  background-color: #075E54 !important;
  color: #fff !important;
}

.caro h2 {
  font-family: Montserrat;
  font-size: 48px !important;
}

.caro p {
  font-family: Montserrat;
  font-size: 36px !important;
}

._uRvDV {
  height: 300px !important;
}

.caro {
  height: 400px !important;
}


@media only screen and (max-width: 425px) {
  .carousel_root {
    max-height: 133px;
  }

  .carousel_resp {
    max-width: 100vw;
    max-height: 133px !important;
  }

  .control-dots {
    visibility: hidden;
  }

  .control-arrow {
    height: 133px;
  }
}

@media only screen and (max-width: 768px) {
  .carousel_resp {
    width: 100vw;
    height: fit-content;
  }
  .control-dots {
    visibility: hidden;
  }
}
