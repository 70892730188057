.buy_message_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
}

.buy_message_semi_container {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #191919;
  padding: 0.5rem;
  border-radius: 10px; 
}

.buy_message {
  font-size: 22px;
  color: #191919;
  margin-bottom: 0;
}

.join_button_option {
  padding: 0.25rem 0.5rem;
  text-align: center;
  background-color: #f99c38;
  color: #191919;
  font-weight: 500;
  font-size: 16px;
  width: 160px;
  border-radius: 10px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.join_button_option:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
}

@media screen and (max-width: 567px) {
  .buy_message_semi_container {
    width: 100%;
  }

  .join_button_option {
    width: auto;
    font-weight: 500;
    font-size: 12px;
  }

  .buy_message{
    font-size: 16px;
    color: #191919;
  }

  .join_button_option:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
  }
}
