.workshop_container {
  display: flex;
  padding: 2rem 0rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.workshop_subcontainer {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 65%;
  color: #191919;
  gap: 2rem;
  padding: 1rem;
}

.workshop_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
}

.workshop_content{
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2rem;
  font-size: 17px;
  color: #191919;
  font-weight: 500;
}

.buy_message_container_workshop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #191919;
  padding: 0.5rem;
  border-radius: 10px;
  width: 100%;
}

.workshop_messageg_content{
  font-size: 22px;
  color: #191919;
  font-weight: 500;
  margin:0;
}

.join_button_option {
  padding: 0.25rem 0.5rem;
  text-align: center;
  background-color: #f99c38;
  color: #191919;
  font-weight: 500;
  font-size: 16px;
  width: 160px;
  border-radius: 10px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.join_button_option:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
}

@media screen and (max-width:976px){
  .workshop_content{
    flex-wrap: wrap;
  }

  .workshop_content > div{
    width: calc(50% - 2rem);
  }
}


@media screen and (max-width: 567px) {
  .workshop_container {
    display: flex;
    padding: 2rem 1rem 2rem 2rem;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .workshop_subcontainer {
    width: 100%;
    padding: 0rem;
  }

  .workshop_heading {
    font-size: 36px;
  }
  
  .workshop_content{
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding-bottom: 1rem;
    font-size: 16px;
    width: 100%;
    color: #191919;
    font-weight: 500;
  }


  .workshop_content> div {
    width: 90%;
  }

  .join_button_option {
    width: auto;
    font-weight: 500;
    font-size: 12px;
  }

  .workshop_messageg_content {
    font-size: 14px;
    color: #191919;
  }

  .join_button-option:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
  }
}