
.appinfo-body{
    background-color: #F8F9FA;
    margin:0px auto;
    padding: 10px 0px; 
    display: flex;
    justify-content: center;
}

.appinfo-body .appinfo-main{
    width:65%;
    background-color:#FFF;
    padding:30px;
}


@media (max-width:768px) {
    .appinfo-body .appinfo-main{
        width:85%;
        padding: 30px 20px;
    }        
}



@media (max-width:768px) {
    .appinfo-body .appinfo-main{
        width:97.5%;
        padding: 20px 10px;
    }        
}

