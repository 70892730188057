.header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.2rem;
  /* background-image: url('/images/headerBG3.jpeg'); */
  padding: 0;
  padding-bottom: 3rem;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.navbarContainer{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
}

.navbarContents{
  width: calc(90% - 140px);
  align-items: center;
  justify-content: center;
  display: flex;
  color: #191919;
  gap:2rem;
  font-size: 16px;
  font-weight: 500;
}

.navbaroption{
  cursor: pointer;
}

.header_container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.65rem;
  padding: 1rem;
  width: 75%;
  background: rgba(239, 239, 239, 0.5);
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.header_heading {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
  color: #191919;
}

.header_subHeading {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  color: #191919;
}

.course_date {
  color: #191919;
  display: flex;
  font-weight: 400;
  gap: 2rem;
}

.course_info {
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
}

.course_subInfo {
  font-size: 20px;
  font-weight: 500;
  color: #191919;
}

.divider {
  border-right: 2px solid #7b7b7b;
}

.courseDetails1 {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
}

.courseDetails2 {
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 1rem;
}

.apply_button {
  padding: 0.5rem 0.75rem;
  text-align: center;
  background-color: #f99c38;
  border: 1.5px solid #191919;
  color: #191919;
  font-weight: 500;
  font-size: 20px;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.apply_button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
}

.apply_Message {
  font-size: 15px;
  color: #191919;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .header_heading {
    font-size: 32px;
    line-height: 1.75;
  }

  .header_container2 {
    width: 85%;
  }
}

@media screen and (max-width: 567px) {

  .navbarContainer{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }

  .navbarContents{
    display: none;
  }

  .header_heading {
    font-size: 36px;
    width: 350px;
    line-height: 65.08px;
  }

  .navbar_options_container {
    display: none;  
  }

  .navbar > img{
    width: 120px;
  }

  .header_container2 {
    width: 95%;
    font-size: 18px;
  }

  .apply_button {
    padding: 0.25rem 0.5rem;
    font-size: 17px;
    width: 160px;
  }

  .header_subHeading {
    font-size: 16px;
  }

  .header_course_details {
    padding: 1rem;
    width: 380px;
  }

  .course_date {
    display: flex;
    gap: 0px;
    padding: 0 1rem;
    justify-content: space-evenly;
  }

  .course_subInfo {
    font-size: 18px;
  }
}

/*
@media screen and (max-width: 767px) {

} */
