.tarinerinfo_container {
  display: flex;
  padding: 1rem 2rem 2rem 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff;
}

.trainerinfo_subcontainer {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 65%;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
}

.trainerinfo_subheading {
  font-size: 19px;
  font-weight: 500;
  width: 60%;
}

.tarinerinfo_heading_container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.trainerinfo_heading {
  font-size: 40px;
  font-weight: 450;
  line-height: 1.25;
}

.trainerinfo_heading > span {
  font-weight: bold;
}

.ashfaq_image {
  width: 100px;
  border-radius: 6px;
}

.trainer_name {
  font-size: 22px;
  font-weight: 500;
}

.trainer_designation {
  font-size: 18px;
  font-weight: 450;
  color: #b32800;
}

.trainer_about_info {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.trainer_about {
  padding-top: 4rem;
  font-size: 20px;
  font-weight: 450;
}

.trainer_about > span {
  font-weight: bold;
}

.trainer_message_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
}

.trainer_message_semi_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #191919;
  padding: 0.5rem;
  border-radius: 10px;
}

.trainer_message_semi_container > p {
  font-size: 20px;
  color: #191919;
}

.join_button_option {
  padding: 0.25rem 0.5rem;
  text-align: center;
  background-color: #f99c38;
  color: #191919;
  font-size: 16px;
  width: auto;
  border-radius: 10px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.join_button_option > div {
  font-weight: 550;
}

.join_button_option:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
}

.message {
  font-size: 14px;
  font-weight: 500;
}

.trainer_message_box {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 33%;
  left: -16%;
  transform: translate(-50%, -50%) rotate(-10deg);
}

.message_arrow {
  position: absolute;
  top: 75%;
  left: 25%;
  width: 100px;
}

.highlight_animation {
  animation: fadeIN 2s infinite ease-in-out;
}

@keyframes fadeIN {
  0%,
  100% {
    opacity: 0.6;
    transform: translate(-50%, -50%) rotate(-10deg) scale(0.97);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(-10deg) scale(1);
  }
}

@media screen and (max-width: 567px) {
  .tarinerinfo_container {
    display: flex;
    padding: 2rem 1rem 2rem 2rem;
  }

  .trainerinfo_subcontainer {
    width: 100%;
    gap: 1rem;
  }

  .tarinerinfo_heading {
    font-size: 36px;
  }

  .trainerinfo_subheading {
    width: 80%;
    font-size: 18px;
    font-weight: 500;
  }

  .join_button_option {
    width: auto;
    font-weight: 500;
    font-size: 12px;
  }

  .join_button_option:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
  }

  .message {
    font-size: 10px;
    font-weight: 500;
    display: none;
  }

  .trainer_message_box {
    display: none;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 47%;
    left: -19%;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .message_arrow {
    position: absolute;
    top: 75%;
    left: 5%;
    width: 20px;
    display: none;
  }
}
