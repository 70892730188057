.cpo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4rem 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  position: sticky;
  top: -40px;
  z-index: 1000;
  background: #fff;
}

.cpo_content {
  border-radius: 30px;
  background: #f8e9d554;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #191919;
}

.cpo_options {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
  border-radius: 30px;
  flex: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #191919;
}

.selected_option {
  background: #f8e9d5;
}

@media screen and (max-width: 567px) {
  .cpo_content {
    width: 100%;
  }

  .cpo_options {
    font-size: 14px;
  }
}
