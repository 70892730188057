.moreinfo_container {
  display: flex;
  padding: 2rem 0rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.moreinfo_subcontainer {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 65%;
  color: #191919;
  gap: 2rem;
  padding: 1rem;
}

.moreinfo_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
}

.moreinfo_subheading {
  font-size: 24px;
  font-weight: 450;
  line-height: 1.5;
}

.moreinfo_content {
  background: #f8e9d5;
  border: 1.5px solid #191919;
  width: 105%;
  padding: 2rem 2rem;
  border-radius: 6px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
}

.moreinfo_content_heading {
  font-size: 26px;
  font-weight: 550;
}

.subheading_content_container {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.moreinfo_subheadings {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 21px;
  font-weight: 450;
}

.subheading_pointer {
  width: 8px;
  height: 8px;
  background: #000;
}

.moreInfo_cards_contanier {
  display: flex;
  gap: 1rem;
  max-width: 100%;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  scroll-padding: 30px;
  overflow-x: scroll;
}

.moreInfo_cards_contanier::-webkit-scrollbar {
  width: 100px; /* Set the width of the scrollbar */
  height: 0;
}

.moreinfo_card_container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 400px;
  padding: 1rem 2rem 2rem 2rem;
  border: 1.5px solid #000;
  background: #fff;
  border-radius: 6px;
  gap: 1rem;
  transition: ease-in-out 0.45s; 
  z-index: 100;
}

.moreinfo_card_container:hover {
  /* min-height: 290px; */
  max-height: auto;
  /* transform: translateY(-2px); */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.742); 
}

.video_container {
  background: #f2f2f2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreInfo_card_content_heading {
  color: #191919;
  font-size: 22px;
  font-weight: 550;
  line-height: 1.5;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: height 0.3s ease;
  cursor: pointer;
}

.full_heading{
  width: auto; /* Allows the heading to expand */
  -webkit-line-clamp: unset; /* Removes the line clamp */
  overflow: visible; 
}

.moreIfo_card_info_container {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 2rem;
  height: 80px;
}

.subheading_text {
  padding: 0.5rem 0;
}

.moreinfo_image_container {
  width: 55px;
  height: 55px;
}

.moreInfo_text_containe {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: #191919;
}

.moreInfo_card_name {
  font-size: 20px;
  font-weight: 550;
}

.moreInfo_card_designation,
.moreInfo_card_company {
  font-size: 14px;
  font-weight: 500;
}

.slider_buttons_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 15%;
  padding: 2rem 1rem;
  gap: 1rem;
}

.slider_image {
  width: 15px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .moreinfo_container {
    padding: 0rem 1rem 2rem 2rem;
  }

  .moreIfo_card_info_container {
    gap: 3rem;
  }

  .moreinfo_subcontainer {
    width: 100%;
    gap: 1rem;
    padding: 0rem;
  }

  .moreinfo_heading {
    font-size: 36px;
  }

  .moreinfo_subheading {
    font-size: 18px;
    padding-bottom: 1rem;
  }

  .moreinfo_content {
    width: 100%;
    padding: 1rem 1rem;
  }

  .moreinfo_content_heading {
    font-size: 22px;
    font-weight: 500;
  }

  .moreinfo_subheadings {
    padding: 0 0.5rem;
    font-size: 18px;
  }

  .subheading_text {
    width: 95%;
  }

  .subheading_pointer {
    width: 6px;
    height: 6px;
  }

  .moreInfo_cards_contanier {
    display: flex;
    gap: 0.5rem;
    max-width: 100%;
    height: auto;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
    scroll-padding: 30px;
    overflow-x: scroll;
  }

  .moreInfo_cards_contanier {
    overflow-y: scroll; /* Keep scrolling functionality */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }
  
  .moreInfo_cards_contanier::-webkit-scrollbar {
    width: 0px; /* Safari and Chrome */
    display: none;
  }
  

  .moreinfo_card_container {
    width: 100%;
  }

  .moreinfo_image_container {
    width: 45px;
    height: 45px;
  }

  .moreInfo_card_name {
    font-size: 18px;
  }

  .moreInfo_card_designation,
  .moreInfo_card_company {
    font-size: 13px;
    font-weight: 500;
  }
}
