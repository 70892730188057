 .video-message-section .swiper-container {
    width: 480px;
  }
  
  @media screen and (min-width: 640px) {
     .video-message-section .swiper-container {
      width: 640px;
    }
  }
  
  @media screen and (min-width: 768px) {
     .video-message-section .swiper-container {
      width: 768px;
    }
  }

  .video-message-section .video-player{
    /* position: absolute; */
    /* height: 360 !important;
    width: 640px !important;
    max-width: 100% !important; */
    border-radius: 3px;
    cursor: -webkit-grab;
    cursor: grab;
    background-color: gray;
    overflow: hidden;
    will-change: transform;
    transform: translateZ(0);
    box-shadow: 0.1rem 0.1rem 1.25rem rgba(0, 0, 0, 0.7);
  }

   
  .video-message-section .video-player {
    vertical-align: middle;
    width:580px !important;
    height:330px !important;
  }
  
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1000px) {
  .video-message-section .video-player {
    vertical-align: middle;
    width: 500px !important;
    height: 270px !important
  }
}

  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .video-message-section .video-player {
      vertical-align: middle;
      width: 440px !important;
      height: 220px !important
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .video-message-section .video-player {
      vertical-align: middle;
      width: 80vw !important;
      height:auto !important
    }
  }