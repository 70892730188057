@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* width */
body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.Tmon {
  min-height: 250px;
  max-height: 250px;
  height: 250px;
  margin-bottom: 10px;
  background-color: #fff !important;
}

.names {
  height: 100px;
}

.blog-container {
  background-color: rgb(243, 247, 247);
  justify-content: space-evenly;
  padding-top: 30px;
  box-shadow: 0 2px 0 0 #ecf1f2;
  border-radius: 8px;
}

.counter {
  padding-left: 7px;
  padding-top: 10px;
  font-size: 24px;
}

#content {
  margin-top: 20px;
}

#content p {
  margin: 0px !important;
}

#draw .MuiDrawer-paper {
  width: 50% !important;
  background-color: rgb(243, 247, 247);
}

.mob-icon .MuiListItemText-root {
  text-align: left;
  padding-left: 5px;
}

.mob-icon {
  border-bottom: 2px solid #fff !important;
  border-radius: 0px !important;
}

.mob-menu {
  display: none;
}

.subHead .btn {
  background-color: none;
  color: #b32800;
  margin-left: 15px;
}

.blog-content {
  background-color: #fff;
  padding-top: 20px;
}

.ql-toolbar span,
.ql-toolbar svg {
  font-size: 18px !important;
}

.ql-container {
  min-height: 300px;
  font-size: 16px !important;
}

.blog-content span {
  padding: 10px;
  color: #6c6c6c;
  font-size: 18px;
  font-family: Montserrat;
}

.blog-content #content {
  padding: 15px;
  padding-bottom: 30px;
  color: #6c6c6c;
}

.blog-content h2 {
  line-height: 28px;
  margin: 0px 0 30px;
  padding-top: 5px;
  padding-left: 10px;
  color: #1c1c1c;
}

.post-title span {
  font-size: 18px;
  line-height: 28px;
  margin: -5px 0 30px;
  letter-spacing: 8px;
  padding-right: 20px;
  color: #1c1c1c;
}

.author-p {
  color: #6c6c6c;
}

.author-image {
  float: left;
  margin-right: 25px;
}

.author-image img {
  width: 150px;
  height: 150px;
}

.single-card {
  display: flex;
  flex-direction: row;
  box-shadow: none !important;
}

.chip-container {
  margin-top: -30px;
  margin-bottom: 25px;
}

.float {
  float: right;
}

.taken {
  display: inline-flex;
  padding: 5px;
  font-size: 16px;
}

.count {
  color: #b32800;
  padding-right: 4px;
}

.count-icon {
  color: #f99c38 !important;
  margin-top: -3px !important;
}

.single-card img {
  border-radius: 16px;
  height: 165px;
}

.button-default {
  background-color: #b32800 !important;
  color: #fff !important;
}

.take-quiz {
  color: #b32800 !important;
  border-color: #b32800 !important;
}

.take-quiz:hover {
  background-color: #b32800 !important;
  color: #fff !important;
}

.card-div {
  width: 92% !important;
  margin-bottom: 10px;
}

.googleBtn {
  background-color: #dc4e41;
  width: 300px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 16px;
  align-items: center;
  font-weight: 600;
  border: none;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.fbBtn {
  background-color: #1877f2;
  width: 300px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 13px;
  color: white;
  font-weight: 600;
  align-items: center;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.googleBtn img {
  width: 20px;
  height: 20px;
  padding: 2px;
  margin: 0px 5px;
  vertical-align: middle;
}

.login {
  justify-content: center;
  align-content: center;
  height: 60vh;
  background: white;
  text-align: center;
}

.head {
  font-size: 28px;
}

.cards {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.MuiIconButton-root span {
  font-size: 1rem !important;
}

.card {
  padding-bottom: 20px;
}

@media only screen and (min-width: 767px) {
  .card {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {

  .fbBtn,
  .googleBtn {
    width: 261px;
  }
}

.name {
  padding-left: 20px;
  font-size: 24px !important;
}

.logout {
  padding-right: 20px;
}

.links {
  color: white;
  text-decoration: none;
}

.quiz {
  padding-top: 20px;
}

.admin-table {
  width: 100%;
  border: 2px solid #ddd;
  border-spacing: 0px;
  text-align: center;
}

.admin-table th {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.admin-table td {
  text-align: center;
  padding: 5px;
}

.admin-table th {
  background-color: darkgray;
}

.admin-table td,
.admin-table th {
  border: 1px solid black;
}

.admin-table tr:nth-child(even),
.drawer {
  background-color: #f2f2f2;
}

.clock {
  width: 110px;
  text-align: center;
  padding: 0px 15px 0px 15px;
  color: #b32800;
  border-radius: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 30px !important;
}

.questions {
  margin: auto !important;
}

.Test-Head {
  padding-bottom: 30px;
  text-align: center;
}

.names h3 {
  padding: 0px !important;
  margin: 0px !important;
  padding-top: 10px !important;
}

.blog-img,
#content img {
  width: 100%;
  max-height: 400px;
}

.names p {
  margin: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}

.media img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.media2 {
  height: 0;
  padding-top: 56.25%;
  background-size: contain !important;
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.chip {
  margin-right: 5px;
}

.detail {
  justify-content: space-between;
  padding-left: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.title h3 {
  margin-top: 5px !important;
}

.title h5 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 400;
  margin-top: 7px !important;
}

.score {
  color: blue;
}

.login {
  background-image: url("https://recruitingmonk.com/wp-content/uploads/2020/03/Profile-back.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.logo {
  width: 180px;
  height: 60px;
  float: left;
  margin-left: 25px;
}

.sidebar {
  background-color: rgb(243, 247, 247);
  border-radius: 16px;
  padding: 25px;
}

.sidebar h2 {
  color: #b32800;
  display: flex;
}

.sidebar .MuiCardContent-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.side-card {
  cursor: pointer;
}

.card {
  max-height: 85vh;
  overflow: auto;
}

.card::-webkit-scrollbar {
  width: 10px;
  scroll-margin-top: 20px;
}

/* Track */
.card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.card::-webkit-scrollbar-thumb {
  background: #b0343c;
  border-radius: 5px;
}

/* Handle on hover */
.card::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

@media only screen and (max-width: 767px) {
  .login {
    height: 60vh;
  }

  .cards {
    padding-left: 0px;
  }

  .sidebar {
    border-radius: 12px;
    padding: 12px;
    position: relative;
  }

  .logo {
    padding-left: 5px;
  }

  .logout {
    padding-right: 5px;
  }

  .name {
    padding-left: 5px;
  }
}

.MuiFormHelperText-root {
  color: red !important;
}

.more {
  margin-top: 20px;
  margin-bottom: 50px;
}

.top {
  justify-content: space-between;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-toolbar {
  display: none;
}

.root {
  font-family: "Montserrat";
  margin-left: 50px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

.linkedin {
  color: #0e76a8 !important;
  float: right;
  right: 50%;
}

.td-name .MuiIconButton-root {
  padding: 0px !important;
}

.root .td-name {
  text-align: left;
  padding: 15px;
}

.root td {
  text-align: center;
  padding: 5px;
}

.root tr {
  border-bottom: 1px solid #e7eeef;
}

.cat-chooser .MuiInput-underline:before,
.cat-chooser .MuiInput-underline:after {
  border-bottom: none !important;
}

.cat-chooser select {
  padding-left: 30px;
}

.question-cat-container .cat-chooser-select {
  background: white !important;
  padding: 0px !important;
}

.cat-chooser {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
}

.leader-quiz {
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 50px;
  width: auto !important;
  justify-content: space-between;
}

.leaders {
  width: 97%;
}

.cat-chooser .MuiNativeSelect-icon {
  display: none !important;
}

.filter-label {
  align-self: center;
  color: rgba(0, 0, 0, 0.54);
}

.MuiNativeSelect-select:focus {
  background-color: white !important;
}

.side-bar {
  padding-left: 10px;
}

@media only screen and (max-width: 990px) {
  .card {
    max-height: 600px;
    overflow: auto;
  }

  .card h1 {
    font-size: 24px !important;
  }

  .Tmon {
    min-height: 200px;
  }

  .mob-menu {
    display: block;
    color: #b32800;
    width: 50px;
  }

  #mob {
    justify-content: space-between;
  }

  .mob-menu .icons {
    color: #b32800 !important;
  }

  .icons {
    color: #b32800 !important;
  }

  .subHead {
    display: none;
  }

  .blog-content #content {
    padding: 10px;
  }

  .chip-container {
    margin-top: -20px;
  }

  .side-bar {
    padding-left: 0px;
  }

  .leaders {
    width: 100%;
  }

  .MuiTablePagination-actions,
  .MuiTablePagination-selectRoot {
    margin: 0px !important;
  }

  .leader-quiz {
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .root .td-name {
    text-align: left;
    padding: 7px;
  }

  .MuiNativeSelect-select:focus {
    background-color: transparent !important;
  }

  .root td {
    padding: 2px;
  }

  .cat-chooser select {
    padding-left: 2px;
  }

  .cat-chooser {
    padding: 2px;
    background-color: transparent;
    box-shadow: none;
  }

  .leaders label {
    display: none !important;
  }

  .root {
    margin: 0px;
  }

  .linkedin {
    float: right;
    right: 0%;
  }

  .single-card {
    display: block;
    box-shadow: none !important;
  }

  .card-div {
    width: 100% !important;
  }

  .detail {
    padding-left: 0px;
  }
}

/* Blog Css */

.Blog {
  border: none;
  line-height: 20px;
  height: auto;
  z-index: 1;
  padding-bottom: 20px;
}

.Blog .bpHead {
  text-align: center;
}

.Blog .body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  cursor: pointer;
}

.Blog .body::-webkit-scrollbar {
  display: none;
}

.Blog .body::-moz-scrollbar {
  display: none;
}

.Blog .body .body1 {
  margin: 10px;
  width: 280px;
  height: 300px;
  padding: 5px;
  line-height: normal;
  border-radius: 7px;
  position: relative;
  z-index: 5;
}

.body1 .bgTitle {
  width: 260px;
  color: #fff;
  z-index: 10;
}

.bgTitle p {
  position: absolute;
  bottom: 0px;
}

.Blog .body {
  overflow: scroll;
}

.Blog .body::-webkit-scrollbar {
  display: block;
}

.Blog .body::-moz-scrollbar {
  display: block;
}

.Blog .body::-webkit-scrollbar:horizontal {
  height: 11px;
}

.Blog .body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  /* background-color: rgba(0, 0, 0, .5); */
  background-color: #b0343c;
}

@media only screen and (min-width: 800px) {
  .Blog {
    padding: 10px;
    margin-top: 40px;
    padding-bottom: 30px;
  }
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#menu-draw .MuiDrawer-paper {
  width: 300px;
  margin-top: 75px !important;
}

.content {
  transition: ease-in;
  transition-delay: 0ms;
  transition-duration: 500ms;
  margin-left: 0px;
  padding: 15px;
}

/* .contentShift {
  width: calc(100% - 300px);
  transition: ease-out;
  transition-delay: 0ms;
  transition-duration: 500ms;
  margin-left: 300px;
} */

.vids {
  width: 98%;
  height: 500px;
}

#menu-m {
  height: 30px;
  color: #b32800;
}

.head-men {
  padding: 10px;
  margin-top: -5px;
}

.links {
  text-decoration: none !important;
  color: #000000 !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

/* .Question-container,
.Reply-container {
  background: #ffffff;
  padding: 20px;
} */
.Question-container,
.Reply-container {
  padding: 20px;
}

.Question-field,
.Reply-field {
  background: rgba(0, 0, 0, 0.12);
}

.Question-field .Mui-focused,
.Reply-field .Mui-focused {
  background: #fff;
}

.Submit-Question,
.Submit-Reply {
  background: #b0343c !important;
  color: #ffffff !important;
}

.ques-div,
.replies-div {
  display: flex;
  align-items: center;
}

.ques-box {
  padding: 10px;
  border: 2px solid #f2f2f2;
  border-radius: 16px;
}

.replies-box {
  padding: 10px;
  border: 4px solid #f2f2f2;
  margin-bottom: 5px !important;
}

.caption {
  margin-top: -15px;
  color: gray;
  font-size: 16px;
}

#ans {
  color: #707885;
}

.Reply-container textarea {
  min-height: 50px !important;
}

.pop-ques .MuiDialog-paper {
  height: 80vh;
  width: 80vw !important;
  max-width: 80vw !important;
}

#emp {
  width: 100%;
  min-height: 700px !important;
}

.task-men {
  position: inherit !important;
}

body {
  padding-right: 0px !important;
}

.task-men .MuiDialog-container {
  min-height: 100vh !important;
}

.top-quiz-title {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.show-ques {
  font-size: 22px;
  font-weight: 500;
}

.show-all {
  text-align: left;
  padding: 20px;
}

/* .qna {
  padding: 175px;
} */

.names {
  display: flex;
}

.names img {
  border-radius: 50%;
  width: 90px !important;
  height: 90px !important;
}

.names div {
  padding-left: 20px;
}

.EM-btn {
  background-color: #ad2831 !important;
  color: white !important;
}

.MuiLinearProgress-bar {
  background-color: #ad2831 !important;
}

.MuiLinearProgress-root {
  background-color: #ad283167 !important;
}

.msg-bot {
  position: fixed;
  right: 8%;
  bottom: 5%;
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
}

.custom-modal {
  background: #ffffff;
}

.phone-input {
  display: flex;
  justify-content: space-evenly;
}


#certificate {
  background-color: #fff;
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/elearning-monks.appspot.com/o/certificate-bg.png?alt=media&token=3c939016-5285-4962-9808-056815790eab"); */
  background-size: 210mm 297mm;
}

#certificate .heading {
  position: absolute;
  top: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
  font-size: 420%;
  font-family: 'PT Serif', serif;
  font-weight: 900;
  color: #6d241b;
}

#certificate .text {
  position: absolute;
  top: 40%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 50%;
  font-size: 240%;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#certifcate .text .certificate-titles {
  color: #ad2931;
  font-weight: 800;
}

#certificate .signature {
  position: absolute;
  top: 82.5%;
  height: 20%;
  font-size: 120%;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


#certificate .footing {
  position: absolute;
  bottom: 0px;
  height: 40px;
  width: 100%;
  background-color: #f99c38;
  text-align: start;
  padding: 7.5px;
}


/* Certificate of Completion -> PT Serif -> Bold -> color: #6d241b
Name/Course Name -> Montserrat  -> #b32800

Ashfaq Ahmed -> PT Serif -> Bold -> #b32800 */