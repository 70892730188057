.success-section .swiper {
  width: 100%;
  height: 100%;
}

.success-section .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}


.success-section .swiper-container {
    width: 480px;
  }
  
  @media screen and (min-width: 576px) {
    .success-section .swiper-container {
      width: 576px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .success-section .swiper-container {
      width: 768px;
    }
  }