.info_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
}

.info_sub_container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 65%;
  color: #191919;
  gap: 1.5rem;
}

.info_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
}

.info_text {
  font-size: 21px;
  font-weight: 450;
  line-height: 1.5;
}

@media screen and (max-width: 576px) {
  .info_container {
    padding: 2rem 1rem 2rem 2rem;
  }

  .info_sub_container {
    width: 100%;
  }

  .info_heading {
    font-size: 36px;
  }

  .info_text {
    font-size: 18px;
  }
}
