.sp_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  z-index: 100;
  background: #fff;
}

.sp_subcontainer {
  width: 65%;
  display: flex;
  align-items: start;
  justify-content: start;
  color: #191919;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  position: relative;
}

.sp_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
}

.sp_video_container {
  padding: 0.5rem;
  border: 1.5px solid #000;
  width: 100%;
  border-radius: 6px;
}

.message {
  font-size: 14px;
  font-weight: 500;
}

.sp_message_box {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  left: -15%;
  transform: translate(-50%, -50%) rotate(-10deg);
}

.message_arrow {
  position: absolute;
  top: 75%;
  left: 25%;
  width: 160px;
}

.highlight_animation {
    animation: fadeIN 2s infinite ease-in-out;
}

@keyframes fadeIN {
    0%, 100% {
        opacity: 0.6;
        transform: translate(-50%, -50%) rotate(-10deg) scale(0.97);
    }
    50% {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(-10deg) scale(1);
    }
}


@media screen  and (max-width: 576px) {

  .sp_container{
    padding: 2rem 1rem 2rem 2rem;
  }

  .sp_subcontainer {
    width: 100%;
  }
  
  .sp_heading {
    font-size: 36px;
  }
  
  .sp_video_container {
    padding: 0.5rem;
    border: 1.5px solid #000;
    width: 80%;
    border-radius: 6px;
    margin-left: calc(20% - 1rem);
  }
  
  .message {
    font-size: 10px;
    font-weight: 500;
  }
  
  .sp_message_box {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 45%;
    left: 4%;
    transform: translate(-50%, -50%) rotate(-10deg);
  }
  
  .message_arrow {
    position: absolute;
    top: 75%;
    left: 5%;
    width: 70px;
  }
}