.trainer_testimonial_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 0 1rem 0;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .trainer_testimonial_card {
    border: 1.5px solid #000;
    border-radius: 6px;
    width: calc(33.333% - 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    cursor: pointer;
  }

  .trainer_testimonial_card_horizontal{
    border: 1.5px solid #000;
    border-radius: 6px;
    width: calc(50% - 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    cursor: pointer;
  }
  
  .screenshot_image {
    width: 100%;
  }
  
  .pagination_count {
    font-size: 16px;
    font-weight: 500;
  }
  
  .pagination_container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    justify-content: end;
    gap: 1rem;
  }
  
  .page_button {
    border: none;
    outline: none;
    background: none;
  }
  
  .pagination_buttons {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .pagination_button {
    cursor: pointer;
    width: 8px;
  }
  
  @media screen and (max-width: 921px) {
    .trainer_testimonial_card {
      width: 100%;
    }

    .trainer_testimonial_card_horizontal{
      width: 100%;
    }
  }
  