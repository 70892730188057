.learning_container {
  display: flex;
  padding: 2rem 0rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.learning_subcontainer {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 65%;
  color: #191919;
  gap: 1rem;
  padding: 1rem;
}

.learning_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
}

.learning_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem 0rem;
  flex-wrap: wrap;
  padding: 2rem 0;
}

.learning_content_container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  width: calc(33.333% - 3rem);
  height: 250px;
}

.learning-content_image {
  width: 120px;
  height: 120px;
}

.content_heading {
  color: #4a5254;
  font-weight: 550;
  font-size: 15px;
}

.subheading {
  font-size: 20px;
  color: #191919;
  font-weight: 500;
}

@media screen and (max-width:976px){
  .learning_content_container {
    width: calc(66.333% - 7rem);
  }
}


@media screen and (max-width: 576px) {
  .learning_container {
    display: flex;
    padding: 2rem 1rem 2rem 2rem;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .learning_subcontainer {
    width: 100%;
    padding: 0rem;
  }

  .learning_heading {
    font-size: 36px;
  }

  .content-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .learning_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 2rem 0;
  }

  .learning_content_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    height: 250px;
    align-items: center;
    justify-content: center;
  }

  .learning-content_image {
    width: 100px;
    height: 100px;
  }

  .content-heading {
    color: #4a5254;
    font-weight: 550;
    font-size: 15px;
  }

  .subheading {
    font-size: 20px;
    color: #191919;
    font-weight: 500;
  }
}
