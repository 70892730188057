 
  .course-videos .video-player {
    vertical-align: middle;
    width:580px !important;
    height:330px !important;
  }
  
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1000px) {
  .course-videos .video-player {
    vertical-align: middle;
    width: 500px !important;
    height: 270px !important
  }
}

  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .course-videos .video-player {
      vertical-align: middle;
      width: 440px !important;
      height: 220px !important
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .course-videos .video-player {
      vertical-align: middle;
      width: 80vw !important;
      height:auto !important
    }
  }