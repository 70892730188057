.testimonial_outer_container {
  padding: 2rem 1rem;
}

.testimonial_container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 2rem;
  padding: 1rem 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  color: #191919;
  height: calc(120vh);
  position: relative;
  z-index: 1;
  border-radius: 20px;
  border: 1px solid #000;
}

.testimonail_heading {
  font-size: 48px;
  font-weight: 400;
  color: #191919;
  line-height: 1.25;
}

.testimonail_heading_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.testimonails_options {
  display: flex;
  border: 1.5px solid #000;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background: white;
  color: #a8a8a8;
  text-align: center;
  width: 25%;
}

.testimonial_option {
  font-size: 15px;
  font-weight: 450;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
}

.selectedtestimonialOption {
  background: #f99c38;
  color: #191919;
}

.testimonial_card_containers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  position: relative;
}

.testimonial_card {
  background: #f9eddd;
  border: 1.5px solid #000;
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  width: 300px;
  height: 380px;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
  border-radius: 10px;
  color: #191919;
  cursor: pointer;
  position: absolute;
  transition: all 0.5s ease;
}

.testimonial_card_content {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}

.testimonial_card_info {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  width: 100%;
}

.testimonial_card_info_leader{
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 0rem 2rem;
  gap: 1rem;
  width: 100%;
}

.testimonial_image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}

.testimonial_card_info_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.testimonial_card_name {
  font-size: 17px;
  font-weight: 550;
}

.testimonial_card_position {
  font-size: 15px;
  font-weight: 450;
}

.testimonial_card_company {
  font-size: 15px;
  font-weight: 450;
}

.testimonial_card.default {
  transform: rotate(0deg);
}

.testimonaial_cards_positioning0 {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 2;
}

.testimonaial_cards_positioning1 {
  top: 55%;
  left: 35%;
  transform: translate(-50%, -50%) rotate(-10deg);
  z-index: 2;
}
.testimonaial_cards_positioning2 {
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%) rotate(-5deg);
  z-index: 2;
}
.testimonaial_cards_positioning3 {
  top: 38%;
  right: -10%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 2;
}

.testimonaial_cards_positioning4 {
  top: 35%;
  left: 15%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 2;
}

.testimonaial_cards_positioning5 {
  top: 55%;
  left: 15%;
  transform: translate(-50%, -50%) rotate(12deg);
  z-index: 1;
}

.testimonaial_cards_positioning6 {
  top: 45%;
  right: 0%;
  transform: translate(-50%, -50%) rotate(-10deg);
  z-index: 1;
}

.testimonaial_cards_positioning7 {
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 3;
}


.testimonial_video_card {
  display: flex;
  background: #cc6f54;
  border: 1.5px solid #000;
  align-items: start;
  justify-content: space-evenly;
  width: 300px;
  height: 450px;
  flex-direction: column;
  padding: 1.25rem 0;
  gap: 1rem;
  border-radius: 10px;
  color: #191919;
  cursor: pointer;
  position: absolute;
  transition: all 0.5s ease;
}

.testimonial_top_card_video {
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: 101;
  background: #c25333;
}

.testimonial_video_card_content {
  font-size: 20px;
  font-weight: 500;
  padding: 0 1.5rem;
}

.testimonial_video_container {
  display: flex;
  align-items: flex-start;
  background: #fff;
}

.testimonial_card_info_text_video {
  display: flex;
  padding: 0 1.5rem;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.testimonial_card_leader {
  background: #f9eddd;
  border: 1.5px solid #000;
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  width: 300px;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
  border-radius: 10px;
  color: #191919;
  cursor: pointer;
  position: absolute;
  transition: all 0.5s ease;
}

.leader_img {
  width: 240px;
  height: 220px;
  object-fit: cover;
  background: #fff;
}

.leader_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
}

.leader_name {
  font-weight: 550;
  font-size: 22px;
}

.leader_designation {
  font-weight: 450;
  font-size: 16px;
}

.leader_company {
  font-weight: 450;
  font-size: 16px;
}

.testimonaial_cards_positioning8 {
  top: 55%;
  left: 18%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 2;
}

.testimonaial_cards_positioning9 {
  top: 49%;
  left: 40%;
  transform: translate(-50%, -50%) rotate(-12deg);
  z-index: 1;
}

.testimonaial_cards_positioning10 {
  top: 55%;
  right: -5%;
  transform: translate(-50%, -50%) rotate(-10deg);
  z-index: 1;
}

.testimonaial_cards_positioning11 {
  top: 58%;
  left: 58%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 3;
}

/* leaders */
.testimonaial_cards_positioning15 {
  top: 58%;
  left: 48%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 4;
}

.testimonaial_cards_positioning16 {
  top: 55%;
  left: 34%;
  transform: translate(-50%, -50%) rotate(-10deg);
  z-index: 3;
}

.testimonaial_cards_positioning17 {
  top: 57%;
  left: 60%;
  transform: translate(-50%, -50%) rotate(8deg);
  z-index: 5;
}

.testimonaial_cards_positioning18 {
  top: 59%;
  left: 20%;
  transform: translate(-50%, -50%) rotate(6deg);
  z-index: 5;
}

.testimonaial_cards_positioning19 {
  top: 59%;
  left: 77%;
  transform: translate(-50%, -50%) rotate(-16deg);
  z-index: 3;
}

.testimonaial_cards_positioning20 {
  top: 65%;
  left: 78%;
  transform: translate(-50%, -50%) rotate(10deg);
  z-index: 3;
}

.testimonial_top_card-leader {
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: 101;
  background: #c25333;
}

.testimonial_top_card {
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: 101;
  background: #fcd1a3;
}


@media screen and (min-width: 1400px){
  .testimonial_container{
    height: 110vh;
  }
}

@media screen and (max-width:976px){
  .testimonaial_cards_positioning0 {
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(10deg);
    z-index: 2;
  }
  
  .testimonaial_cards_positioning1 {
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 3;
  }
  .testimonaial_cards_positioning2 {
    top: 45%;
    left: 25%;
    transform: translate(-50%, -50%) rotate(5deg);
    z-index: 2;
  }
  
  .testimonaial_cards_positioning3 {
    top: 65%;
    left: 25%;
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 2;
  }
  
  .testimonaial_cards_positioning4 {
    top: 55%;
    left: 77%;
    transform: translate(-50%, -50%) rotate(10deg);
    z-index: 2;
  }
  
  .testimonaial_cards_positioning5 {
    top: 28%;
    left: 77%;
    transform: translate(-50%, -50%) rotate(-5deg);
    z-index: 1;
  }
  
  .testimonaial_cards_positioning6 {
    top: 25%;
    left: 25%;
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 2;
  }
  
  .testimonaial_cards_positioning7 {
    top: 65%;
    left: 74%;
    transform: translate(-50%, -50%) rotate(15deg);
    z-index: 3;
  }
  
  .testimonial_top_card {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 110;
    background: #fcd1a3;
  }
  
  .testimonial_top_card_video {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 101;
    background: #c25333;
  }
  
  .testimonaial_cards_positioning15 {
    top: 45%;
    left: 25%;
    transform: translate(-50%, -50%) rotate(-5deg);
    z-index: 2;
  }
  
  .testimonaial_cards_positioning16 {
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-12deg);
    z-index: 1;
  }
  
  .testimonaial_cards_positioning17 {
    top: 48%;
    left: 77%;
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 1;
  }
  
  .testimonaial_cards_positioning18 {
    top: 60%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 3;
  }

  .testimonaial_cards_positioning19 {
    top: 65%;
    left: 29%;
    transform: translate(-50%, -50%) rotate(10deg);
    z-index: 2;
  }
  
  .testimonial_top_card {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 120;
    background: #fcd1a3;
  }

  .testimonial_top_card-leader {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 101;
    background: #c25333;
  }

  .testimonial_top_card_video {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 120;
    background: #c25333;
  }
}

@media screen and (max-width: 576px) {
  .testimonial_outer_container {
    padding: 2rem 0.25rem;
  }

  .testimonial_container {
    padding: 2rem;
  }

  .testimonail_heading-section {
    flex-direction: column;
    padding: 1rem 0;
    gap: 2rem;
  }

  .testimonail_heading_section{
    flex-direction: column;
    gap: 2rem;
  }

  .testimonail_heading {
    font-size: 36px;
    font-weight: 550;
  }

  .testimonails_options {
    width: 90%;
  }

  .testimonaial_cards_positioning0 {
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(5deg);
    z-index: 2;
  }

  .testimonaial_cards_positioning5 {
    top: calc(8% + 360px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(12deg);
    z-index: 1;
  }

  .testimonaial_cards_positioning1 {
    top: calc(8% + 700px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-5deg);
    z-index: 2;
  }

  .testimonaial_cards_positioning4 {
    top: calc(8% + 1050px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(6deg);
    z-index: 2;
  }

  .testimonaial_cards_positioning6 {
    top: calc(8% + 1400px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-10deg);
    z-index: 1;
  }
  .testimonaial_cards_positioning2 {
    top: calc(8% + 1800px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-3deg);
    z-index: 2;
  }

  .testimonaial_cards_positioning3 {
    top: calc(8% + 2170px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(10deg);
    z-index: 2;
  }

  .testimonaial_cards_positioning7 {
    top: calc(8% + 2560px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-4deg);
    z-index: 3;
  }

  .testimonaial_cards_positioning15 {
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(5deg);
    z-index: 1;
  }

  .testimonaial_cards_positioning16 {
    top: calc(400px + 16%);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-5deg);
    z-index: 1;
  }

  .testimonaial_cards_positioning19 {
    top: calc(800px + 15%);
    left: 50%;
    transform: translate(-50%, -50%) rotate(5deg);
    z-index: 1;
  }

  .testimonaial_cards_positioning18 {
    top: calc(1200px + 13%);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-5deg);
    z-index: 2;
  }

  .testimonaial_cards_positioning17 {
    top: calc(1400px + 22%);
    left: 50%;
    transform: translate(-50%, -50%) rotate(5deg);
    z-index: 1;
  }

  .testimonaial_cards_positioning20 {
    top: calc(1750px + 22%);
    left: 50%;
    transform: translate(-50%, -50%) rotate(-6deg);
    z-index: 1;
  }

  .testimonial_top_card {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 120;
    background: #fcd1a3;
  }

  .testimonial_top_card-leader {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 101;
    background: #c25333;
  }

  .testimonial_top_card_video {
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: 120;
    background: #c25333;
  }
}